<template>
  <div style="width: 100%;">
    <v-container fluid>
      <v-row>
        <v-col class='d-flex flex-row align-center'>
          <v-btn text class="mx-2" :to="'/'+this.pluralLower">
            <v-icon dark>mdi-arrow-left</v-icon>
          </v-btn>
          <h1>{{this.singular}}: {{this.data.name}}</h1>
          <v-progress-circular
            indeterminate
            color="green"
            v-if="loader"
            style="margin-left: 10px;"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn v-if="isAllowed('branch','u')" @click="isEditable = !isEditable">
            <span v-if="this.isEditable">Cancel</span>
            <span v-else>Edit</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-form :disabled="!isEditable">
        <v-row>
          <v-col>
            <v-text-field
                label="Name"
                outlined
                v-model="data.name"
            />
            <v-text-field
                label="Address"
                outlined
                v-model="data.address"
            />
            <v-text-field
                label="Description"
                outlined
                v-model="data.description"
            />
          </v-col>
        </v-row>
      </v-form>
      <v-row>
        <v-col v-if="isEditable">
          <v-btn @click="update">Save</v-btn>
          <v-btn v-if="isAllowed('branch','d')" style="margin-left: 10px;" class="error" @click="deleteData">Delete</v-btn>
        </v-col>
      </v-row>
      <hr>
      <v-row>
        <v-col cols="8">
          <v-container>
            <v-row>
              <v-col class="d-flex flex-row align-center">
                <h1>Warehouses</h1>
                <v-progress-circular
                    indeterminate
                    color="green"
                    v-if="loader"
                    style="margin-left: 10px;"
                ></v-progress-circular>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-data-table
                    :headers="warehouseHeaders"
                    :items="data.Warehouses"
                    :items-per-page="-1"
                    class="elevation-1"
                    @click:row="warehouseRowClick"
                >
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col v-if="isAllowed('warehouse','c')" cols="4">
          <v-container>
            <v-row>
              <v-col class='d-flex flex-row align-center'>
                <h1>Add New Warehouse</h1>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                    label="Name"
                    outlined
                    v-model="warehouse.name"
                ></v-text-field>
                <v-text-field
                    label="Description"
                    outlined
                    v-model="warehouse.description"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn @click="createWarehouse" :disabled="!this.warehouse.name">Create</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import axios from 'axios';
  import {mapGetters} from "vuex";
  export default {
    data () {
      return {
        deleteDialog: false,
        deleteConfirmed: true,
        loader: true,

        snackObj: {
          state: false,
          color: '',
          text: ''
        },

        max25chars: v => v.length <= 25 || 'Input too long!',
        editMode: false,
        data: {},

        warehouseHeaders: [
          {
            text: 'Name',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          { text: "Description", value: 'description'}
        ],
        warehouse: {},

        singular: "Branch",
        singularLower: "branch",
        plural: "Branches",
        pluralLower: "branches",
        isEditable: false,
      }
    },
    async mounted(){
        try {
          let res = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}/${this.$route.params.id}`)
          if(res.data.error) throw res.data.error

          this.data = res.data.data
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        } finally {
          this.loader = false;
        }
    },
    computed: {
      ...mapGetters(['getEndpoint', "isAllowed"])
    },
    methods: {
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      async update(){
        try {
          this.loader = true;
          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/${this.$route.params.id}`, this.data)
          if(res.data.error) throw res.data.error
          if(res.data.success){
            this.snack(`${this.singular} info Updated 🎉`, "success");
            this.isEditable = false;
          }
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        } finally {
          this.loader = false;
        }
      },
      async deleteData(){
        try {
          this.loader = true
          let res = await axios.delete(`${this.getEndpoint}/api/${this.pluralLower}/${this.$route.params.id}`)
          if(res.data.error) throw res.data.error

          this.snack(this.singular+" Deleted 🎉", "success");
          await this.$router.go(-1)
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        } finally {
          this.loader = false
        }
      },
      warehouseRowClick(warehouse){
        this.$router.push({ path: `/warehouses/view/${warehouse.id}`})
      },
      async createWarehouse(){
        try {
          let res = await axios.post(`${this.getEndpoint}/api/warehouses`, this.warehouse)
          if(res.data.error) throw res.data.error

          this.snack(`Warehouse Created 🎉`, "success");
          this.warehouse={};
          await this.getAllData();
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        }
      },
    }
  }
</script>
